import arrayObjectHelper from './arrayObjectHelper';

const includedScripts = [];
const includedStylesheets = [];

const include = (includedItems, buildTag) => url => new Promise((resolve) => {
  if (arrayObjectHelper.includes(includedItems, url)) { resolve(); }

  const tag = buildTag(resolve, url);
  document.getElementsByTagName('head')[0].appendChild(tag);
  includedItems.push(url);
});

export const includeScript = include(includedScripts, (resolve, url) => {
  const tag = document.createElement('script');
  tag.type = 'text/javascript';
  tag.src = url;
  tag.onload = resolve;

  return tag;
});

export const includeStylesheet = include(includedStylesheets, (resolve, url) => {
  const tag = document.createElement('link');
  tag.rel = 'stylesheet';
  tag.type = 'text/css';
  tag.href = url;
  tag.onload = resolve;

  return tag;
});

export const includeScripts = (urls) => {
  const scriptLoadPromises = urls.map(includeScript);
  return Promise.all(scriptLoadPromises);
};
