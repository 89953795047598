import { includeScripts } from '../includeHelper';
import deviceDataHelper from './init';

let context = {};

const init = (ctx) => {
  context = ctx;
};

const fetchDeviceDataConfig = (createIframes = true) => {
  const { serverHost, publicKey, gatewayHandle, deviceData } = context.globalUserOptions;

  context.sendMessageToMainIframe({ action: 'FETCH_DEVICE_DATA_CONFIG', data: {
      serverHost: serverHost,
      publicKey: publicKey,
      gatewayHandle: gatewayHandle,
      deviceData: deviceData,
      createIframes,
    },
  });
};

const receivedDeviceDataConfig = async function (data) {
  includeScripts(data.braintree_srcs).then(() => {
    deviceDataHelper.initDeviceDataCollector(data.client_token);
  });

  if (data.createIframes) await context.createIframes();
};

const receivedDeviceDataConfigError = () => {
  context.globalUserOptions.deviceData = false;
};

export default {
  init,
  fetchDeviceDataConfig,
  receivedDeviceDataConfig,
  receivedDeviceDataConfigError,
};
