const store = {
  reference: null,
  formData: null,
  storedProfile: null,
};

// API over mutable global state
const accessStore = {
  fetchFormData: () => store.formData,
  fetchReference: () => store.reference,
  fetchStoredProfile: () => store.storedProfile,
  saveFormData: (formData) => {
    store.formData = formData;
  },
  saveReference: (reference) => {
    store.reference = reference;
  },
  saveStoredProfile: (storedProfile) => {
    store.storedProfile = storedProfile;
  },
};

export default accessStore;
