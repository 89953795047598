import Honeybadger from '@honeybadger-io/js';
import config from '../config/config';

// Ensure our configuration doesn't affect clients' own;
const configureBadger = (cfg) => {
  const { enableUncaught, enableUnhandledRejection } = cfg;
  let onErrorBefore;
  let onUnhandledRejectionBefore;

  if (!enableUncaught) {
    onErrorBefore = window.onerror;
    window.onerror = null;
  }
  if (!enableUnhandledRejection) {
    onUnhandledRejectionBefore = window.onunhandledrejection;
    window.onunhandledrejection = null;
  }

  const ChargifyBadger = Honeybadger.factory(cfg); // Changes event listeners on window

  if (!enableUncaught) {
    window.onerror = onErrorBefore;
  }
  if (!enableUnhandledRejection) {
    window.onunhandledrejection = onUnhandledRejectionBefore;
  }

  return ChargifyBadger;
};

const isLocalhost = () => Boolean(
  window.location.hostname === 'js.chargify.test' ||
        window.location.hostname === 'localhost' ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === '[::1]' ||
        // 127.0.0.1/8 is considered localhost for IPv4.
        window.location.hostname.match(
          /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
        ),
);

const buildContext = (hostLocation, options) => ({
  user_id: options.serverHost, // HB special context value to aggregate user data
  type: options.type,
  serverHost: options.serverHost,
  gatewayHandle: options.gatewayHandle,
  threeDSecure: options.threeDSecure,
  deviceData: options.deviceData,
  selfSelector: options.selfSelector,
  href: hostLocation,
});

const shouldSendException = () => !isLocalhost();

const ChargifyBadger = configureBadger(config.Honeybadger);

export default {
  notify(error, hostLocation, userOptions = {}) {
    if (!shouldSendException()) return;

    ChargifyBadger.notify(error, {
      context: buildContext(hostLocation, userOptions),
    });
  },
};
