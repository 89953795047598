/* globals ApplePaySession braintree */

import loggerHelper from '../loggerHelper';
import initBraintreeClient from '../braintree_blue/init';

const buttonNodeId = '#cfy-apple-pay-button';
let applePayButtonSelector = '';

const validateEnv = (onApplePayNotSupportedCallback) => {
  if (!window.ApplePaySession) {
    onApplePayNotSupportedCallback();
    loggerHelper.error('This device does not support Apple Pay');
    return false;
  }
  if (!ApplePaySession.canMakePayments()) {
    onApplePayNotSupportedCallback();
    loggerHelper.error('This device is not capable of making Apple Pay payments');
    return false;
  }
  return true;
};

const applePayButtonElem = () => document.querySelector(applePayButtonSelector).querySelector(buttonNodeId);

const hideApplePayButton = () => {
  applePayButtonElem().style.display = 'none';
};

const showApplePayButton = () => {
  applePayButtonElem().style.display = 'inline-block';
};

const paymentRequestParams = applePayModalOptions => ({
  total: {
    label: applePayModalOptions.applePayLabel,
    amount: applePayModalOptions.applePayAmount,
  },
  requiredBillingContactFields: ['postalAddress'],
});

const handleClickingOnApplePayButton = (applePayInstance, applePayModalOptions, callbacks) => {
  applePayButtonElem().addEventListener('click', (e) => {
    e.preventDefault();

    const paymentRequest = applePayInstance.createPaymentRequest(paymentRequestParams(applePayModalOptions));
    const session = new ApplePaySession(3, paymentRequest);

    session.onvalidatemerchant = (event) => {
      applePayInstance.performValidation({
        validationURL: event.validationURL,
        displayName: 'My Store',
      })
        .then(merchantSession => session.completeMerchantValidation(merchantSession))
        .catch((err) => {
          callbacks.onApplePayError(err);
          loggerHelper.error('Error validating merchant:', err);
          session.abort();
        });
    };

    session.onpaymentauthorized = (event) => {
      applePayInstance.tokenize({ token: event.payment.token })
        .then((payload) => {
          callbacks.paymentMethodNonceReceived(payload.nonce);
          session.completePayment(ApplePaySession.STATUS_SUCCESS);
          hideApplePayButton();
        })
        .catch((err) => {
          callbacks.onApplePayError(err);
          loggerHelper.error('Error tokenizing Apple Pay:', err);
          session.completePayment(ApplePaySession.STATUS_FAILURE);
        });
    };

    session.begin();
  });
};

export default (applePayButtonElemSelector, clientToken, applePayModalOptions, callbacks) => {
  applePayButtonSelector = applePayButtonElemSelector;
  if (!validateEnv(callbacks.onApplePayNotSupported)) return;

  initBraintreeClient(clientToken)
    .then(clientInstance => braintree.applePay.create({ client: clientInstance }))
    .then(applePayInstance =>
      ApplePaySession.canMakePaymentsWithActiveCard(applePayInstance.merchantIdentifier)
        .then((canMakePaymentsWithActiveCard) => {
          if (canMakePaymentsWithActiveCard) {
            showApplePayButton();
            handleClickingOnApplePayButton(applePayInstance, applePayModalOptions, callbacks);
          } else {
            callbacks.onApplePayError('This device is not capable of making Apple Pay payments.');
          }
        }),
    ).catch((err) => {
      callbacks.onApplePayError(err);
      loggerHelper.error('ApplePay error:', err);
    });
};
