
// since we can't use lodash and we need to support IE10,
// we have to implement this functions ourselves

const includes = function (arr, item) {
  let found = false;

  arr.forEach((eachItem) => {
    if (eachItem === item) {
      found = true;
    }
  });

  return found;
};

const assign = function (target) {
  if (target === null) {
    throw new TypeError('Cannot convert undefined or null to object');
  }

  const to = Object(target);

  for (let index = 1; index < arguments.length; index++) {
    const nextSource = arguments[index];

    if (nextSource !== null) {
      for (const nextKey in nextSource) {
        if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
          to[nextKey] = nextSource[nextKey];
        }
      }
    }
  }

  return to;
};

const getNestedObject = (nestedObj, pathArr) => pathArr.reduce((obj, key) =>
  ((obj && obj[key] !== 'undefined') ? obj[key] : undefined), nestedObj);

export default {
  includes,
  assign,
  getNestedObject,
};
