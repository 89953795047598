let context = {};

const init = (ctx) => {
  context = ctx;
};

const fetchDirectDebitData = () => {
  context.sendMessageToMainIframe({ action: 'FETCH_DIRECT_DEBIT_DATA' });
};

export default {
  init,
  fetchDirectDebitData,
};
