import { includeScript, includeScripts } from './includeHelper';

import cyberSource from './cyber_source/index';
import genericThreeDSecure from './generic_three_d_secure/index';
import adyenHelpers from './adyen';
import braintreeThreeDSHelper from '../helpers/braintree_blue/three_ds';
import blueSnapThreeDSHelper from '../helpers/blue_snap/three_ds';
import deviceDataHelper from '../helpers/device_data/init';
import checkoutHelpers from './checkout';

let context = {};

const setContext = (ctx) => {
  context = ctx;
};

const init = (config, callbacks) => {
  switch (config.gateway) {
    case 'cybersource':
      initCyberSource(config, callbacks);
      break;
    case 'adyen':
      adyenHelpers.init(config);
      break;
    case 'nostra-adyen':
      context.sendMessageToMainIframe({ action: 'NOSTRA_ADYEN_INIT_3DS', data: config });
      break;
    case 'braintree_blue':
      initBraintree(config, callbacks);
      break;
    case 'blue_snap':
      initBlueSnap(config, callbacks);
      break;
    case 'payment_express':
      break; // no additional init
    case 'checkout':
      checkoutHelpers.init(callbacks);
      break;
    default:
      console.warn('3D Secure initialization failed. Unsupported gateway');
  }
};

const initCardAuthorization = (formData, config, callbacks) => {
  switch (config.gateway) {
    case 'cybersource':
      cyberSource.authorize(formData, config, callbacks);
      break;
    case 'payment_express':
      genericThreeDSecure.authorize(formData, config, callbacks);
      break;
    case 'braintree_blue':
      braintreeThreeDSHelper.verifyCard(formData, config, callbacks);
      break;
    case 'adyen':
      adyenHelpers.startAuthorization(callbacks);
      break;
    case 'nostra-adyen':
      context.sendMessageToMainIframe({ action: 'NOSTRA_ADYEN_START_3DS_AUTHORIZATION' });
      break;
    case 'blue_snap':
      blueSnapThreeDSHelper.verifyCard(formData, config);
      break;
    case 'checkout':
      checkoutHelpers.startAuthorization(callbacks);
      break;
    default:
      console.warn('3D Secure card authorization failed. Unsupported gateway');
  }
};

const challengeRequested = (gateway, threeDSData) => {
  switch (gateway) {
    case 'cybersource':
      return threeDSData && threeDSData.enrollment != null;
    case 'adyen':
      return threeDSData != null;
    case 'nostra-adyen':
      return threeDSData != null;
    case 'checkout':
      return threeDSData != null;
    default:
      return false;
  }
};

const initChallenge = (gateway, threeDSData, onThreeDSSuccess) => {
  switch (gateway) {
    case 'cybersource':
      cyberSource.startChallenge(threeDSData, onThreeDSSuccess);
      break;
    case 'adyen':
      adyenHelpers.showChallengeModal(threeDSData, onThreeDSSuccess);
      break;
    case 'nostra-adyen':
      context.sendMessageToMainIframe({ action: 'NOSTRA_ADYEN_SHOW_3DS_CHALLENGE_MODAL', data: threeDSData });
      context.displayMainIframe();
      break;
    case 'checkout':
      checkoutHelpers.showChallengeModal(threeDSData, onThreeDSSuccess);
      break;
    default:
      break;
  }
};

const initCyberSource = (config, callbacks) => {
  includeScript(config.songbird_src).then(() => (
    cyberSource.init(config, callbacks)
  ));
};

const initBraintree = (config, callbacks) => {
  includeScripts(config.braintree_srcs).then(() => {
    braintreeThreeDSHelper.init(config, callbacks);
  }).then(() => {
    if (deviceDataHelper.isDeviceDataScriptIncluded(config.braintree_srcs)) {
      deviceDataHelper.initDeviceDataCollector(config.client_token);
    }
  });
};

const initBlueSnap = (config, callbacks) => {
  includeScripts(config.blue_snap_src).then(() => {
    blueSnapThreeDSHelper.init(config, callbacks);
  });
};

export const removeModalFromDom = () => {
  document.querySelectorAll('.tingle-modal').forEach(elem => elem.remove());
};

export default {
  init,
  initCardAuthorization,
  challengeRequested,
  initChallenge,
  setContext,
  removeModalFromDom,
};
