
const getFieldLabel = function (field, options) {
  const label = field.label;
  let additionalLabel = '';

  if (field.required && options.requiredLabel) {
    additionalLabel = ` <span class="cfy-additional-label">${options.requiredLabel || ''}</span>`;
  } else if (!field.required) {
    additionalLabel = ` <span class="cfy-additional-label">${options.optionalLabel || '(optional)'}</span>`;
  }

  return label + additionalLabel;
};

const getSelector = function (elementType, fieldName) {
  const prefix = `.cfy-${elementType}`;

  if (fieldName) {
    return `${prefix}--${fieldName}`;
  }

  return prefix;
};

const show = function (elem, displayAs) {
  displayAs = displayAs == null ? 'block' : displayAs;
  elem = document.querySelector(elem);
  if (elem == null) return;
  elem.style.display = displayAs;
};

const hide = function (elem) {
  if (typeof elem === 'string') {
    elem = document.querySelector(elem);
  }

  if (elem == null) return;
  elem.style.display = 'none';
};

export default {
  getFieldLabel: getFieldLabel,
  getSelector: getSelector,
  show: show,
  hide: hide,
};
