import creditCardHelper from '../creditCardHelper';

const { getCleanCardNumber, isNumberLuhnValid } = creditCardHelper;

export const configurationData = {
  logging: { debug: 'silent' },
  cca: { framework: 'cardinal' },
};

export const setupData = jwt => ({
  jwt,
});

export const orderData = (formData, config) => ({
  OrderDetails: orderDetailsData(config),
  Consumer: consumerData(formData),
});

export const continueData = enrollment => ({
  AcsUrl: enrollment.acs_url,
  Payload: enrollment.payload,
});

export const validateForm = (data, onError) => {
  const billingAddressData = data.Consumer.BillingAddress;
  const accountData = data.Consumer.Account;

  if (!isNumberLuhnValid(accountData.AccountNumber)) {
    onError('card number is invalid');
    return false;
  }

  if (!isPresent(accountData.ExpirationMonth) || !isPresent(accountData.ExpirationYear)) {
    onError('expiration date is invalid');
    return false;
  }

  if (['US', 'CA'].includes(billingAddressData.CountryCode) && !billingAddressData.State) {
    onError('state code is required for US and CA');
    return false;
  }

  return true;
};

export const validationCancelled = data =>
  !!(data.Payment &&
          data.Payment.ExtendedData &&
            data.Payment.ExtendedData.ChallengeCancel);

export const validationSucceeded = (data) => {
  if (data.ActionCode === 'FAILURE' || data.ActionCode === 'ERROR') return false;
  if (data.ErrorNumber && data.ErrorNumber !== 0) return false;

  return true;
};

export const getAccountNumber = data => data.Consumer.Account.AccountNumber;
export const getErrorDescription = data => data.ErrorDescription;

const orderDetailsData = ({ amount, currency_code, order_number, enrollment }) => ({
  Amount: amount,
  CurrencyCode: currency_code,
  OrderNumber: order_number,
  TransactionId: enrollment && enrollment.transaction_id,
});

const clearStateWhenNotRequired = (state, country) =>
  (country && !['US', 'CA'].includes(country) ? '' : state);

const consumerData = formData => ({
  BillingAddress: {
    FirstName: formData.firstName || '',
    LastName: formData.lastName || '',
    Address1: formData.address || '',
    Address2: formData.address2 || '',
    City: formData.city || '',
    State: clearStateWhenNotRequired(formData.state || '', formData.country || ''),
    PostalCode: formData.zip || '',
    CountryCode: formData.country || '',
  },
  Account: {
    AccountNumber: getCleanCardNumber(formData.number) || '',
    NameOnAccount: `${formData.firstName || ''} ${formData.lastName || ''}`,
    CardCode: formData.cvv || '',
    ExpirationMonth: formData.month || '',
    ExpirationYear: formData.year || '',
  },
});

const isPresent = value => !!(value && value.trim().length);
