import config from '../config/config';

const log = function (message, value) {
  if (config.debug) {
    console.log(message, value || ''); // eslint-disable-line no-console
  }
};

const info = function (message, value) {
  if (config.debug) {
    console.info(message, value || '');
  }
};

const warn = function (message, value) {
  if (config.debug) {
    console.warn(message, value || '');
  }
};

const error = function (message, value) {
  if (config.debug) {
    console.error(message, value || '');
  }
};

export default {
  log: log,
  info: info,
  warn: warn,
  error: error,
};
